import { SementicVersion } from './sementic-version.entity';

export class NfcTag {

    public serial!: string;

    public expiration?: Date;
}



export class DeviceNfcTags {

    public id = '';

    public serial = '';

    public version?: SementicVersion;

    public records: Array<string> = [];

}
