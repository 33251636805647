import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { LOCATION_INITIALIZED } from '@angular/common';
import { firstValueFrom } from 'rxjs';

import { JwtInterceptor } from '@lightning/lightning-services';

import { SharedModule } from './shared/shared.module';
import { FallbackModule } from './fallback/fallback.module';

import { AppComponent } from './app.component';

// Regular
// export function httpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http);
// }

// Multiple
export const httpLoaderFactory = (http: HttpBackend): MultiTranslateHttpLoader => {
    return new MultiTranslateHttpLoader(http, [
        'assets/i18n/core/',
        'assets/i18n/shared/',
        'assets/i18n/http/',
        'assets/i18n/operations/',
    ]);
};

const DefaultLanguage = 'fr';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return async () => {

        await injector.get(LOCATION_INITIALIZED, Promise.resolve());

        translate.setDefaultLang(DefaultLanguage);

        await firstValueFrom(translate.use(DefaultLanguage));
    }
}

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule, //SSR .withServerTransition({ appId: 'website' }),
        AppRoutingModule,
        // Multiple
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        // Regular
        // TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: httpLoaderFactory,
        //         deps: [HttpClient],
        //     },
        // }),
        SharedModule,
        FallbackModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
